import React from "react";
import Header from "../../domains/Header/Header";
import './layout.scss';

const Layout = ({ children, routeOptions = {} }) => {
  
  return (
    <div className="layout">

      <Header></Header>    

      { children }
    </div>
  );
}

export default Layout;
