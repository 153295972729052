import React from 'react';
import classnames from 'classnames';
import './answerButton.scss';
import ClickyButton from '../clickyButton/ClickyButton';

const AnswerButton = ({
  text,
  addClass,
  buttonAnswer,
  correctAnswer,
  userAnswer,
  onClick
}) => {
  let theme = classnames({
    // [addClass]: !!addClass,
    'green': buttonAnswer === userAnswer && buttonAnswer === correctAnswer,
    'red': buttonAnswer === userAnswer && buttonAnswer !== correctAnswer,
    // 'otherButton': !!userAnswer && buttonAnswer !== userAnswer
  })  
  return (
    <ClickyButton
      theme={theme}
      onClick={() => onClick(buttonAnswer)}
    >
      {text}
    </ClickyButton>
  )
}

export default AnswerButton;
