import Soundfont from 'soundfont-player';

const ac = new (window.AudioContext || window.webkitAudioContext)();

class InstrumentService {
  constructor() {
    this.loadInstrument()
  }

  loadInstrument = () => {
    Soundfont.instrument(ac, 'soundfonts/piano-mp3.js')
      .then((instrument) => {
        this.instrument = instrument;
      })
  }

  playNote = (note) => {
    this.instrument.play(note);
  }

  playInterval = (firstNote, secondNote) => {
    this.instrument.play(firstNote);
    this.instrument.schedule(
      ac.currentTime,
      [{ time: 1, note: secondNote }]
    );
  }
}

export default InstrumentService;
