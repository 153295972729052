const worldCapitals = [
  {
    front: "Afghanistan",
    back: "Kabul"
  },
  {
    front: "Albania",
    back: "Tirana"
  },
  {
    front: "Algeria",
    back: "Algiers"
  },
  {
    front: "Andorra",
    back: "Andorra la Vella"
  },
  {
    front: "Angola",
    back: "Luanda"
  },
  {
    front: "Antigua and Barbuda",
    back: "St. John's"
  },
  {
    front: "Argentina",
    back: "Buenos Aires"
  },
  {
    front: "Armenia",
    back: "Yerevan"
  },
  {
    front: "Aruba",
    back: "Oranjestad"
  },
  {
    front: "Australia",
    back: "Canberra"
  },
  {
    front: "Austria",
    back: "Vienna"
  },
  {
    front: "Azerbaijan",
    back: "Baku"
  },
  {
    front: "Bahamas",
    back: "Nassau"
  },
  {
    front: "Bahrain",
    back: "Manama"
  },
  {
    front: "Bangladesh",
    back: "Dhaka"
  },
  {
    front: "Barbados",
    back: "Bridgetown"
  },
  {
    front: "Belarus",
    back: "Minsk"
  },
  {
    front: "Belgium",
    back: "Brussels"
  },
  {
    front: "Belize",
    back: "Belmopan"
  },
  {
    front: "Benin",
    back: "Cotonou/Porto-Novo"
  },
  {
    front: "Bermuda",
    back: "Hamilton"
  },
  {
    front: "Bhutan",
    back: "Thimphu"
  },
  {
    front: "Bolivia",
    back: "La Paz and Sucre"
  },
  {
    front: "Bosnia and Herzegovina",
    back: "Sarajevo"
  },
  {
    front: "Botswana",
    back: "Gaborone"
  },
  // {
  //   front: "Brazil",
  //   back: "Brasília"
  // },
  {
    front: "British Virgin Islands",
    back: "Road Town"
  },
  {
    front: "Brunei",
    back: "Bandar Seri Begawan"
  },
  {
    front: "Bulgaria",
    back: "Sofia"
  },
  {
    front: "Burkina Faso",
    back: "Ouagadougou"
  },
  {
    front: "Burundi",
    back: "Gitega and Bujumbura"
  },
  {
    front: "Cambodia",
    back: "Phnom Penh"
  },
  {
    front: "Cameroon",
    back: "Yaoundé"
  },
  {
    front: "Canada",
    back: "Ottawa"
  },
  {
    front: "Cape Verde",
    back: "Praia"
  },
  {
    front: "Cayman Islands",
    back: "George Town"
  },
  {
    front: "Central African Republic",
    back: "Bangui"
  },
  {
    front: "Chad",
    back: "N'Djamena"
  },
  {
    front: "Chile",
    back: "Santiago"
  },
  {
    front: "China",
    back: "Beijing"
  },
  {
    front: "Colombia",
    back: "Bogotá"
  },
  {
    front: "Comoros",
    back: "Moroni"
  },
  {
    front: "Congo",
    back: "Brazzaville"
  },
  {
    front: "Costa Rica",
    back: "San José"
  },
  {
    front: "Croatia",
    back: "Zagreb"
  },
  {
    front: "Cuba",
    back: "Havana"
  },
  {
    front: "Curaçao",
    back: "Willemstad"
  },
  {
    front: "Cyprus",
    back: "Nicosia"
  },
  {
    front: "Czech Republic",
    back: "Prague"
  },
  {
    front: "Democratic Republic of the Congo",
    back: "Kinshasa"
  },
  // {
  //   front: "Denmark",
  //   back: "Copenhagen"
  // },
  // {
  //   front: "Djibouti",
  //   back: "Djibouti"
  // },
  // {
  //   front: "Dominica",
  //   back: "Roseau"
  // },
  // {
  //   front: "Dominican Republic",
  //   back: "Santo Domingo"
  // },
  // {
  //   front: "East Timor",
  //   back: "Dili"
  // },
  // {
  //   front: "Ecuador",
  //   back: "Quito"
  // },
  // {
  //   front: "Egypt",
  //   back: "Cairo"
  // },
  // {
  //   front: "El Salvador",
  //   back: "San Salvador"
  // },
  // {
  //   front: "Equatorial Guinea",
  //   back: "Malabo"
  // },
  // {
  //   front: "Eritrea",
  //   back: "Asmara"
  // },
  // {
  //   front: "Estonia",
  //   back: "Tallinn"
  // },
  // {
  //   front: "Eswatini",
  //   back: "Lobamba and Mbabane"
  // },
  // {
  //   front: "Ethiopia",
  //   back: "Addis Ababa"
  // },
  // {
  //   front: "Faroe Islands",
  //   back: "Tórshavn"
  // },
  // {
  //   front: "Fiji",
  //   back: "Suva"
  // },
  // {
  //   front: "Finland",
  //   back: "Helsinki"
  // },
  // {
  //   front: "France",
  //   back: "Paris"
  // },
  // {
  //   front: "French Polynesia",
  //   back: "Papeete"
  // },
  // {
  //   front: "Gabon",
  //   back: "Libreville"
  // },
  // {
  //   front: "Georgia",
  //   back: "Tbilisi"
  // },
  // {
  //   front: "Germany",
  //   back: "Berlin"
  // },
  // {
  //   front: "Ghana",
  //   back: "Accra"
  // },
  // {
  //   front: "Gibraltar",
  //   back: "Gibraltar"
  // },
  // {
  //   front: "Greece",
  //   back: "Athens"
  // },
  // {
  //   front: "Greenland",
  //   back: "Nuuk"
  // },
  // {
  //   front: "Grenada",
  //   back: "St. George's"
  // },
  // {
  //   front: "Guam",
  //   back: "Hagåtña"
  // },
  // {
  //   front: "Guatemala",
  //   back: "Guatemala City"
  // },
  // {
  //   front: "Guinea",
  //   back: "Conakry"
  // },
  // {
  //   front: "Guinea-Bissau",
  //   back: "Bissau"
  // },
  // {
  //   front: "Guyana",
  //   back: "Georgetown"
  // },
  // {
  //   front: "Haiti",
  //   back: "Port-au-Prince"
  // },
  // {
  //   front: "Honduras",
  //   back: "Tegucigalpa"
  // },
  // {
  //   front: "Hungary",
  //   back: "Budapest"
  // },
  // {
  //   front: "Iceland",
  //   back: "Reykjavík"
  // },
  // {
  //   front: "India",
  //   back: "New Delhi"
  // },
  // {
  //   front: "Indonesia",
  //   back: "Jakarta"
  // },
  // {
  //   front: "Iran",
  //   back: "Tehran"
  // },
  // {
  //   front: "Iraq",
  //   back: "Baghdad"
  // },
  // {
  //   front: "Ireland",
  //   back: "Dublin"
  // },
  // {
  //   front: "Isle of Man",
  //   back: "Douglas"
  // },
  // // {
  // //   front: "Israel",
  // //   back: "Jerusalem"
  // // },
  // {
  //   front: "Italy",
  //   back: "Rome"
  // },
  // {
  //   front: "Ivory Coast",
  //   back: "Yamoussoukro"
  // },
  // {
  //   front: "Jamaica",
  //   back: "Kingston"
  // },
  // {
  //   front: "Japan",
  //   back: "Tokyo"
  // },
  // {
  //   front: "Jersey",
  //   back: "St. Helier"
  // },
  // {
  //   front: "Jordan",
  //   back: "Amman"
  // },
  // {
  //   front: "Kazakhstan",
  //   back: "Astana"
  // },
  // {
  //   front: "Kenya",
  //   back: "Nairobi"
  // },
  // {
  //   front: "Kiribati",
  //   back: "South Tarawa"
  // },
  // {
  //   front: "Kosovo",
  //   back: "Pristina"
  // },
  // {
  //   front: "Kuwait",
  //   back: "Kuwait City"
  // },
  // {
  //   front: "Kyrgyzstan",
  //   back: "Bishkek"
  // },
  // {
  //   front: "Laos",
  //   back: "Vientiane"
  // },
  // {
  //   front: "Latvia",
  //   back: "Riga"
  // },
  // {
  //   front: "Lebanon",
  //   back: "Beirut"
  // },
  // {
  //   front: "Lesotho",
  //   back: "Maseru"
  // },
  // {
  //   front: "Liberia",
  //   back: "Monrovia"
  // },
  // {
  //   front: "Libya",
  //   back: "Tripoli"
  // },
  // {
  //   front: "Liechtenstein",
  //   back: "Vaduz"
  // },
  // {
  //   front: "Lithuania",
  //   back: "Vilnius"
  // },
  // {
  //   front: "Luxembourg",
  //   back: "Luxembourg"
  // },
  // {
  //   front: "Madagascar",
  //   back: "Antananarivo"
  // },
  // {
  //   front: "Malawi",
  //   back: "Lilongwe"
  // },
  // {
  //   front: "Malaysia",
  //   back: "Kuala Lumpur"
  // },
  // {
  //   front: "Maldives",
  //   back: "Malé"
  // },
  // {
  //   front: "Mali",
  //   back: "Bamako"
  // },
  // {
  //   front: "Malta",
  //   back: "Valletta"
  // },
  // {
  //   front: "Marshall Islands",
  //   back: "Majuro"
  // },
  // {
  //   front: "Mauritania",
  //   back: "Nouakchott"
  // },
  // {
  //   front: "Mauritius",
  //   back: "Port Louis"
  // },
  // {
  //   front: "Mexico",
  //   back: "Mexico City"
  // },
  // {
  //   front: "Micronesia",
  //   back: "Palikir"
  // },
  // {
  //   front: "Moldova",
  //   back: "Chișinău"
  // },
  // {
  //   front: "Monaco",
  //   back: "Monaco"
  // },
  // {
  //   front: "Mongolia",
  //   back: "Ulaanbaatar"
  // },
  // {
  //   front: "Montenegro",
  //   back: "Podgorica"
  // },
  // {
  //   front: "Morocco",
  //   back: "Rabat"
  // },
  // {
  //   front: "Mozambique",
  //   back: "Maputo"
  // },
  // {
  //   front: "Myanmar",
  //   back: "Naypyidaw"
  // },
  // {
  //   front: "Namibia",
  //   back: "Windhoek"
  // },
  // {
  //   front: "Nauru",
  //   back: "Yaren"
  // },
  // {
  //   front: "Nepal",
  //   back: "Kathmandu"
  // },
  // {
  //   front: "Netherlands",
  //   back: "Amsterdam / The Hague"
  // },
  // {
  //   front: "New Caledonia",
  //   back: "Nouméa"
  // },
  // {
  //   front: "New Zealand",
  //   back: "Wellington"
  // },
  // {
  //   front: "Nicaragua",
  //   back: "Managua"
  // },
  // {
  //   front: "Niger",
  //   back: "Niamey"
  // },
  // {
  //   front: "Nigeria",
  //   back: "Abuja"
  // },
  // {
  //   front: "North Korea",
  //   back: "Pyongyang"
  // },
  // {
  //   front: "North Macedonia",
  //   back: "Skopje"
  // },
  // {
  //   front: "Northern Ireland",
  //   back: "Belfast"
  // },
  // {
  //   front: "Northern Mariana Islands",
  //   back: "Saipan"
  // },
  // {
  //   front: "Norway",
  //   back: "Oslo"
  // },
  // {
  //   front: "Oman",
  //   back: "Muscat"
  // },
  // {
  //   front: "Pakistan",
  //   back: "Islamabad"
  // },
  // {
  //   front: "Palau",
  //   back: "Ngerulmud"
  // },
  // {
  //   front: "Panama",
  //   back: "Panama City"
  // },
  // {
  //   front: "Papua New Guinea",
  //   back: "Port Moresby"
  // },
  // {
  //   front: "Paraguay",
  //   back: "Asunción"
  // },
  // {
  //   front: "Peru",
  //   back: "Lima"
  // },
  // {
  //   front: "Philippines",
  //   back: "Manila"
  // },
  // {
  //   front: "Poland",
  //   back: "Warsaw"
  // },
  // {
  //   front: "Portugal",
  //   back: "Lisbon"
  // },
  // {
  //   front: "Puerto Rico",
  //   back: "San Juan"
  // },
  // {
  //   front: "Qatar",
  //   back: "Doha"
  // },
  // {
  //   front: "Romania",
  //   back: "Bucarest"
  // },
  // {
  //   front: "Russia",
  //   back: "Moscow"
  // },
  // {
  //   front: "Rwanda",
  //   back: "Kigali"
  // },
  // {
  //   front: "Saint Kitts and Nevis",
  //   back: "Basseterre"
  // },
  // {
  //   front: "Saint Lucia",
  //   back: "Castries"
  // },
  // {
  //   front: "Samoa",
  //   back: "Apia"
  // },
  // {
  //   front: "San Marino",
  //   back: "San Marino"
  // },
  // {
  //   front: "São Tomé and Príncipe",
  //   back: "São Tomé"
  // },
  // {
  //   front: "Saudi Arabia",
  //   back: "Riyadh"
  // },
  // {
  //   front: "Scotland",
  //   back: "Edinburgh"
  // },
  // {
  //   front: "Senegal",
  //   back: "Dakar"
  // },
  // {
  //   front: "Serbia",
  //   back: "Belgrade"
  // },
  // {
  //   front: "Seychelles",
  //   back: "Victoria"
  // },
  // {
  //   front: "Sierra Leone",
  //   back: "Freetown"
  // },
  // {
  //   front: "Singapore",
  //   back: "Singapore"
  // },
  // {
  //   front: "Slovakia",
  //   back: "Bratislava"
  // },
  // {
  //   front: "Slovenia",
  //   back: "Ljubljana"
  // },
  // {
  //   front: "Solomon Islands",
  //   back: "Honiara"
  // },
  // {
  //   front: "Somalia",
  //   back: "Mogadishu"
  // },
  // {
  //   front: "South Africa",
  //   back: "Bloemfontein / Cape Town / Pretoria"
  // },
  // {
  //   front: "South Korea",
  //   back: "Seoul"
  // },
  // {
  //   front: "South Sudan",
  //   back: "Juba"
  // },
  // {
  //   front: "Spain",
  //   back: "Madrid"
  // },
  // {
  //   front: "Sri Lanka",
  //   back: "Colombo / Kotte"
  // },
  // {
  //   front: "Sudan",
  //   back: "Khartoum"
  // },
  // {
  //   front: "Suriname",
  //   back: "Paramaribo"
  // },
  // {
  //   front: "Sweden",
  //   back: "Stockholm"
  // },
  // {
  //   front: "Switzerland",
  //   back: "Bern"
  // },
  // {
  //   front: "Syria",
  //   back: "Damascus"
  // },
  // {
  //   front: "Taiwan",
  //   back: "Taipei"
  // },
  // {
  //   front: "Tajikistan",
  //   back: "Dushanbe"
  // },
  // {
  //   front: "Tanzania",
  //   back: "Dodoma"
  // },
  // {
  //   front: "Thailand",
  //   back: "Bangkok"
  // },
  // {
  //   front: "The Gambia",
  //   back: "Banjul"
  // },
  // {
  //   front: "Togo",
  //   back: "Lomé"
  // },
  // {
  //   front: "Tonga",
  //   back: "Nuku'alofa"
  // },
  // {
  //   front: "Trinidad and Tobago",
  //   back: "Port of Spain"
  // },
  // {
  //   front: "Tunisia",
  //   back: "Tunis"
  // },
  // {
  //   front: "Turkey",
  //   back: "Ankara"
  // },
  // {
  //   front: "Turkmenistan",
  //   back: "Ashgabat"
  // },
  // {
  //   front: "Turks and Caicos",
  //   back: "Cockburn Town"
  // },
  // {
  //   front: "Tuvalu",
  //   back: "Funafuti"
  // },
  // {
  //   front: "Uganda",
  //   back: "Kampala"
  // },
  // {
  //   front: "Ukraine",
  //   back: "Kyiv"
  // },
  // {
  //   front: "United Arab Emirates",
  //   back: "Abu Dhabi"
  // },
  // {
  //   front: "England",
  //   back: "London"
  // },
  // {
  //   front: "United States",
  //   back: "Washington, D.C."
  // },
  // {
  //   front: "US Virgin Islands",
  //   back: "Charlotte Amalie"
  // },
  // {
  //   front: "Uruguay",
  //   back: "Montevideo"
  // },
  // {
  //   front: "Uzbekistan",
  //   back: "Tashkent"
  // },
  // {
  //   front: "Vanuatu",
  //   back: "Port Vila"
  // },
  // {
  //   front: "Venezuela",
  //   back: "Caracas"
  // },
  // {
  //   front: "Vietnam",
  //   back: "Hanoi"
  // },
  // {
  //   front: "Wales",
  //   back: "Cardiff"
  // },
  // {
  //   front: "Yemen",
  //   back: "Sana'a"
  // },
  // {
  //   front: "Zambia",
  //   back: "Lusaka"
  // },
  // {
  //   front: "Zimbabwe",
  //   back: "Harare"
  // }
]

export default worldCapitals;
