import { getRandomInteger, getRandomKeyInObject, getRandomValueInObject } from "../../helpers/functions";

const handleChangeMultiOptions = (options, key, setOptionsFn, allowNone) => {
  const newOptions = {...options};
  const currentValue = options[key];
  const n = Object.keys(options).length

  if (!!currentValue) { // removing
    if (!allowNone && n <= 1) {
      return;
    }

    delete newOptions[key];
  } else { // including
    newOptions[key] = true
  }

  setOptionsFn(newOptions)
}

const generateUpDownChallenge = (roots, maxInterval) => {
  let possibleRoots = Object.keys(roots);
  let n = possibleRoots.length;

  let firstNote = parseInt(possibleRoots[getRandomInteger(0, n - 1)]);
  let interval = getRandomInteger(1, maxInterval)
  let direction = getRandomInteger(0, 1);

  let secondNote = direction === 0
    ? firstNote - interval
    : firstNote + interval;

  let correctAnswer = direction === 0 ? 'down' : 'up';

  let challenge = {
    firstNote,
    secondNote,
    correctAnswer
  };

  return challenge;
}

const generateIntervalChallenge = (roots, intervals, directions) => {
  const firstNote = parseInt(getRandomKeyInObject(roots));
  const interval = parseInt(getRandomKeyInObject(intervals));

  let direction;
  if (directions === "up") {
    direction = 1;
  } else if (directions === "down") {
    direction = 0;
  } else {
    direction = getRandomInteger(0, 1);
  }

  const secondNote = direction === 0
    ? firstNote - interval
    : firstNote + interval;

  const correctAnswer = direction === 0
    ? (-1 * interval)
    : interval;

  let challenge = {
    firstNote,
    secondNote,
    correctAnswer
  };

  return challenge;
}

export { generateUpDownChallenge, generateIntervalChallenge, handleChangeMultiOptions };
