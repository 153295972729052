import React from "react";
import classnames from 'classnames';
import './intervalSelector.scss'
import { intervals as allIntervals} from '../../helpers/intervals';
import { handleChangeMultiOptions } from '../../domains/common/handlers';

const IntervalBox = ({ interval, intervals, setIntervalsFn }) => {  
  return (
    <div
      onClick={() => handleChangeMultiOptions(intervals, interval.semitones, setIntervalsFn)}
      className={classnames({
        'interval-box': true,
        'selected': intervals[interval.semitones]
      })}
    >
      {interval.shortname}
    </div>
  )
}

const IntervalSelector = ({ intervals, setIntervalsFn }) => {
  const firstRow = [0, 1, 2, 3, 4, 5];
  const secondRow = [6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="intervals-container">
      <div className="intervals-row">
        <div className="interval-padder"></div>

        {/* box-shadow: 0 5px 0 #aaaa */}
        {/* .button:active {
          box-shadow: none;
          transform: translateY(5px);
        } */}
        {/* https://twitter.com/builderio/status/1539757162561343488?lang=en */}
        {/* https://codepen.io/ericmahoney/details/oNgVWvx */}

        {firstRow.map(idx => {
          return (<IntervalBox
            key={`interval-box-${idx}`}
            interval={allIntervals[idx]}
            intervals={intervals}
            setIntervalsFn={setIntervalsFn}
          />)}
        )}

        <div className="interval-padder"></div>
      </div>

      <div className="intervals-row">
        {secondRow.map(idx => {
          return (<IntervalBox
            key={`interval-box-${idx}`}
            interval={allIntervals[idx]}
            intervals={intervals}
            setIntervalsFn={setIntervalsFn}
          />)}
        )}
      </div>
    </div>
  )
}

export default IntervalSelector;
