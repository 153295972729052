import React, { useState } from "react";
import './intervals.scss';
import { generateIntervalChallenge } from "../common/handlers";

import AnswerButton from '../../components/answerButton/AnswerButton';
import { ReactComponent as NextSymbol } from '../../images/forward.svg';
import { ReactComponent as PlaySymbol } from '../../images/play.svg';
import Settings from '../Settings/Settings';
import { intervals as allIntervals } from '../../helpers/intervals';
import NoteSelector from "../../components/noteSelector/NoteSelector";
import IntervalSelector from "../../components/intervalSelector/IntervalSelector";
import DirectionsSelector from "../../components/directionsSelector/DirectionsSelector";
import NoteBox from "../../components/noteBox/NoteBox";
import ClickyButton from '../../components/clickyButton/ClickyButton';


const handleClickNextChallenge = (roots, intervals, direction, setChallengeFn, setAnswerFn, pianoService) => {
  setAnswerFn();
  let challenge = generateIntervalChallenge(roots, intervals, direction);
  
  setChallengeFn(challenge);
  
  const { firstNote, secondNote } = challenge;

  pianoService.playInterval(firstNote, secondNote)
}

const handleClickPlayChallenge = (challenge, setChallengeFn, roots, intervals, direction, pianoService) => {
  if (!challenge || !challenge.firstNote) {
    challenge = generateIntervalChallenge(roots, intervals, direction);
  }

  setChallengeFn(challenge)

  const { firstNote, secondNote } = challenge;
  pianoService.playInterval(firstNote, secondNote)
}

const handleAnswerQuestion = ({
  answer, currentAnswer, correctAnswer, setAnswerFn
}) => {
  if (currentAnswer === correctAnswer) {
    return;
  }

  setAnswerFn(parseInt(answer));
}

const Intervals = ({ pianoService }) => {
  const [roots, setRoots] = useState({ 60: true, 67: true })
  const [intervals, setIntervals] = useState({ 4: true, 7: true, 12: true })
  const [currentAnswer, setCurrentAnswer] = useState();
  const [challenge, setChallenge] = useState();
  const [direction, setDirection] = useState("up");

  return (
    <div className="up-down-root"> 
      <Settings
      >
        <NoteSelector
          notes={roots}
          setNotesFn={setRoots}
        />

        <div style={{height:"24px"}}></div>

        <div className="directions-container">
          <DirectionsSelector
            direction={direction}
            setDirectionFn={setDirection}
          ></DirectionsSelector>
        </div>

        <div style={{height:"24px"}}></div>

        <IntervalSelector
          intervals={intervals}
          setIntervalsFn={setIntervals}
        />
      </Settings>

      <div style={{'height': '48px', 'width': '100%'}}/>

      {/* Controls */}
      <div className='control-row'>
        <div className='control-column'>
          <div className='control-wrapper'>
            <ClickyButton
              onClick={() => handleClickPlayChallenge(challenge, setChallenge, roots, intervals, direction, pianoService)}
            >
              <PlaySymbol
                className='control-symbol'
              ></PlaySymbol>
            </ClickyButton>
          </div>
        </div>
        
        <div className='control-column'>
          <div className='control-wrapper'>
            <ClickyButton
              onClick={() => handleClickNextChallenge(roots, intervals, direction, setChallenge, setCurrentAnswer, pianoService)}
            >
              <NextSymbol
                className='control-symbol'
              ></NextSymbol>
            </ClickyButton>
          </div>
        </div>
      </div>

      <div style={{'height': '24px', 'width': '100%'}}/>

      {/* Notes */}
      <div className='control-row'>
        <div className='control-column'>
          <div className='control-wrapper'>
            <NoteBox
              showInfo={!!currentAnswer}
              instrument={pianoService}
              midiValue={challenge?.firstNote}
            ></NoteBox>
          </div>
        </div>
        
        <div className='control-column'>
          <div className='control-wrapper'>
            <NoteBox
              showInfo={!!currentAnswer}
              instrument={pianoService}
              midiValue={challenge?.secondNote}
            ></NoteBox>
          </div>
        </div>
      </div>

      <div style={{'height': '12px', 'width': '100%'}}/>

      <div className="divider"></div>
      
      <div style={{'height': '12px', 'width': '100%'}}/>
      
      <div className="both-answers-outer-wrapper">
        {(direction === "up" || direction === "both") && (
          <div className="answers-outer-wrapper">
            <div className="answers-header">
              Up
            </div>
            <div className="answers-container">
              {Object.keys(intervals).map(interval => {
                return (
                  <AnswerButton
                    key={`up-${interval}`}
                    text={allIntervals[interval].shortname}
                    addClass={'intervals-answer-button'}
                    buttonAnswer={parseInt(interval)}
                    correctAnswer={challenge?.correctAnswer}
                    userAnswer={currentAnswer}
                    onClick={() => handleAnswerQuestion({
                      answer: interval,
                      currentAnswer,
                      correctAnswer: challenge?.correctAnswer,
                      setAnswerFn: setCurrentAnswer
                    })}
                  ></AnswerButton>
                )
              })}
            </div>
          </div>
        )}

        {(direction === "down" || direction === "both") && (
          <div className="answers-outer-wrapper">
            <div className="answers-header">
              Down
            </div>
            <div className="answers-container">
              {Object.keys(intervals).map(interval => {
                return (
                  <AnswerButton
                    key={`down-${interval}`}
                    text={allIntervals[interval].shortname}
                    addClass={'intervals-answer-button'}
                    buttonAnswer={(-1 * parseInt(interval))}
                    correctAnswer={challenge?.correctAnswer}
                    userAnswer={currentAnswer}
                    onClick={() => handleAnswerQuestion({
                      answer: (-1 * parseInt(interval)),
                      currentAnswer,
                      correctAnswer: challenge?.correctAnswer,
                      setAnswerFn: setCurrentAnswer
                    })}
                  ></AnswerButton>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Intervals;
