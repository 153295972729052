// Both min and max included
function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function getRandomKeyInObject(obj) {
  const possibleKeys = Object.keys(obj);
  const nn = possibleKeys.length;
  const key = possibleKeys[getRandomInteger(0, nn - 1)];

  return key;
}

function getRandomValueInObject(obj) {
  const possibleKeys = Object.keys(obj);
  const nn = possibleKeys.length;
  const key = possibleKeys[getRandomInteger(0, nn - 1)];

  return obj[key];
}

function shuffleArray(array) {
  for (let ii = array.length - 1; ii > 0; ii--) {
    const jj = Math.floor(Math.random() * (ii + 1));
    [array[ii], array[jj]] = [array[jj], array[ii]];
  }
}

export {
  getRandomInteger,
  getRandomKeyInObject,
  getRandomValueInObject,
  shuffleArray
};
