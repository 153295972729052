const semitonesToIntervals = [
  {
    front: "0",
    back: "P1 - Perfect unison"
  },
  {
    front: "1",
    back: "m2 - Minor second"
  },
  {
    front: "2",
    back: "M2 - Major second"
  },
  {
    front: "3",
    back: "m3 - Minor third"
  },
  {
    front: "4",
    back: "M3 - Major third"
  },
  {
    front: "5",
    back: "P4 - Perfect fourth"
  },
  {
    front: "6",
    back: "TT - Tritone"
  },
  {
    front: "7",
    back: "P5 - Perfect fifth"
  },
  {
    front: "8",
    back: "m6 - Minor sixth"
  },
  {
    front: "9",
    back: "M6 - Major sixth"
  },
  {
    front: "10",
    back: "m7 - Minor seventh"
  },
  {
    front: "11",
    back: "M7 - Major seventh"
  },
  {
    front: "12",
    back: "P8 - Perfect octave"
  }
]

export default semitonesToIntervals;
