const majorChords = [
  {
    front: "C",
    back: "C - E - G"
  },
  {
    front: "C#",
    back: "C# - E# - G#"
  },
  {
    front: "Db",
    back: "Db - F - Ab"
  },
  {
    front: "D",
    back: "D - F# - A"
  },
  // {
  //   front: "D#",
  //   back: "D# - F## - A#"
  // },
  {
    front: "Eb",
    back: "Eb - G - Bb"
  },
  {
    front: "E",
    back: "E - G# - B"
  },
  {
    front: "F",
    back: "F - A - C"
  },
  {
    front: "F#",
    back: "F# - A# - C#"
  },
  {
    front: "Gb",
    back: "Gb - Bb - Db"
  },
  {
    front: "G",
    back: "G - B - D"
  },
  {
    front: "G#",
    back: "G# - B# - D#"
  },
  {
    front: "Ab",
    back: "Ab - C - Eb"
  },
  {
    front: "A",
    back: "A - C# - E"
  },
  // {
  //   front: "A#",
  //   back: "A# - C## - E#"
  // },
  {
    front: "Bb",
    back: "Bb - D - F"
  },
  {
    front: "B",
    back: "B - D# - F#"
  },
];

export default majorChords;

// https://www.pianochord.org/b-flat-major.html#hide1
