import './directions-selector.scss';
import classNames from 'classnames';

const directions = [
  {
    text: "Up",
    value: "up"
  },
  {
    text: "Down",
    value: "down"
  },
  {
    text: "Both",
    value: "both"
  }
]

const DirectionsSelector = ({ direction, setDirectionFn }) => {
  return (
    <div className='directions-selector-root'>
      {directions.map(dd => {
        return (
          <div
            key={dd.value}
            onClick={() => setDirectionFn(dd.value)}
            className={classNames({
              'directions-selector-box': true,
              'directions-selector-box-selected': dd.value === direction
            })}
          >
            {dd.text}
          </div>
        )
      })}
    </div>
  );

}

export default DirectionsSelector;
