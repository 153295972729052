import classnames from 'classnames';
import './noteSelector.scss';
import { handleChangeMultiOptions } from '../../domains/common/handlers';

const NoteSelector = ({ notes, setNotesFn }) => {  
  return (
    <div className="keyboard-container">
      <div className="white-keys-container">
        <div id="key-c" className={classnames({'white-key': true, 'selected-key': notes[60]})} onClick={() => handleChangeMultiOptions(notes, 60, setNotesFn)}>C</div>
        <div id="key-d" className={classnames({'white-key': true, 'selected-key': notes[62]})} onClick={() => handleChangeMultiOptions(notes, 62, setNotesFn)}>D</div>
        <div id="key-e" className={classnames({'white-key': true, 'selected-key': notes[64]})} onClick={() => handleChangeMultiOptions(notes, 64, setNotesFn)}>E</div>
        <div id="key-f" className={classnames({'white-key': true, 'selected-key': notes[65]})} onClick={() => handleChangeMultiOptions(notes, 65, setNotesFn)}>F</div>
        <div id="key-g" className={classnames({'white-key': true, 'selected-key': notes[67]})} onClick={() => handleChangeMultiOptions(notes, 67, setNotesFn)}>G</div>
        <div id="key-a" className={classnames({'white-key': true, 'selected-key': notes[69]})} onClick={() => handleChangeMultiOptions(notes, 69, setNotesFn)}>A</div>
        <div id="key-b" className={classnames({'white-key': true, 'selected-key': notes[71]})} onClick={() => handleChangeMultiOptions(notes, 71, setNotesFn)}>B</div>
      
        <div className='black-keys-container'>
          <div className='spacer-1'></div>
          <div id="key-c#" className={classnames({'black-key': true, 'selected-key': notes[61]})} onClick={() => handleChangeMultiOptions(notes, 61, setNotesFn)}>C#</div>

          <div className='spacer-2'></div>
          <div id="key-d#" className={classnames({'black-key': true, 'selected-key': notes[63]})} onClick={() => handleChangeMultiOptions(notes, 63, setNotesFn)}>D#</div>

          <div className='spacer-3'></div>
          <div className='spacer-4'></div>

          <div id="key-f#" className={classnames({'black-key': true, 'selected-key': notes[66]})} onClick={() => handleChangeMultiOptions(notes, 66, setNotesFn)}>F#</div>
          <div className='spacer-5'></div>
          
          <div id="key-g#" className={classnames({'black-key': true, 'selected-key': notes[68]})} onClick={() => handleChangeMultiOptions(notes, 68, setNotesFn)}>G#</div>
          <div className='spacer-6'></div>
          
          <div id="key-a#" className={classnames({'black-key': true, 'selected-key': notes[70]})} onClick={() => handleChangeMultiOptions(notes, 70, setNotesFn)}>A#</div>
        </div>
      </div>
    </div>
  )
}

export default NoteSelector;
