const majorScales = [
  {
    front: "C Major",
    back: [
      '0 sharps',
      '',
      '12 o\'clock'
    ],
  },
  {
    front: "G Major",
    back: [
      '1 sharp',
      'F#',
      '1 o\'clock'
    ],
  },
  {
    front: "D Major",
    back: [
      '2 sharps',
      'F# C#',
      '2 o\'clock'
    ],
  },
  {
    front: "A Major",
    back: [
      '3 sharps',
      'F# C# G#',
      '3 o\'clock'
    ],
  },
  {
    front: "E Major",
    back: [
      '4 sharps',
      'F# C# G# D#',
      '4 o\'clock'
    ],
  },
  {
    front: "B Major",
    back: [
      '5 sharps',
      'F# C# G# D# A#',
      '5 o\'clock'
    ],
  },
  {
    front: "F# Major",
    back: [
      '6 sharps',
      'F# C# G# D# A# E#',
      '6 o\'clock'
    ],
  },
  // {
    // front: "C# Major",
    // back: [
    //   '7 sharps',
    //   'F# C# G# D# A# E# B#',
    //   '7 o\'clock'
    // ],
  // },
  
  {
    front: "F Major",
    back: [
      '1 flat',
      'Bb',
      '11 o\'clock'
    ],
  },
  {
    front: "Bb Major",
    back: [
      '2 flats',
      'Bb Eb',
      '10 o\'clock'
    ],
  },
  {
    front: "Eb Major",
    back: [
      '3 flats',
      'Bb Eb Ab',
      '9 o\'clock'
    ],
  },
  {
    front: "Ab Major",
    back: [
      '4 flats',
      'Bb Eb Ab Db',
      '8 o\'clock'
    ],
  },
  {
    front: "Dd Major",
    back: [
      '5 flats',
      'Bb Eb Ab Db Gb',
      '7 o\'clock'
    ],
  },
  {
    front: "Gb Major",
    back: [
      '6 flats',
      'Bb Eb Ab Gb Db Cb',
      '6 o\'clock'
    ],
  },
  // {
  //   front: "Cb Major",
  //   back: [
  //     '7 flats',
  //     'Bb Eb Ab Gb Db Cb Fb',
  //     '5 o\'clock'
  //   ],
  // }
]

export default majorScales
