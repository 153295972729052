// https://www.inspiredacoustics.com/en/MIDI_note_numbers_and_center_frequencies

const midiToNote = {
  // : {
  //   midi: ,
  //   note: '',
  //   accidental: '',
  //   octave: 
  // },
  48: {
    midi: 48,
    note: 'C',
    accidental: '',
    octave: 3
  },
  49: {
    midi: 49,
    note: 'C',
    accidental: '#',
    octave: 3
  },
  50: {
    midi: 50,
    note: 'D',
    accidental: '',
    octave: 3
  },
  51: {
    midi: 51,
    note: 'D',
    accidental: '#',
    octave: 3
  },
  52: {
    midi: 52,
    note: 'E',
    accidental: '',
    octave: 3
  },
  53: {
    midi: 53,
    note: 'F',
    accidental: '',
    octave: 3
  },
  54: {
    midi: 54,
    note: 'F',
    accidental: '#',
    octave: 3
  },
  55: {
    midi: 55,
    note: 'G',
    accidental: '',
    octave: 3
  },
  56: {
    midi: 56,
    note: 'G',
    accidental: '#',
    octave: 3
  },
  57: {
    midi: 57,
    note: 'A',
    accidental: '',
    octave: 3
  },
  58: {
    midi: 58,
    note: 'A',
    accidental: '#',
    octave: 3 
  },
  59: {
    midi: 59,
    note: 'B',
    accidental: '',
    octave: 3
  },
  60: {
    midi: 60,
    note: 'C', // middle C
    accidental: '',
    octave: 4
  },
  61: {
    midi: 61,
    note: 'C',
    accidental: '#',
    octave: 4
  },
  62: {
    midi: 62,
    note: 'D',
    accidental: '',
    octave: 4
  },
  63: {
    midi: 63,
    note: 'D',
    accidental: '#',
    octave: 4
  },
  64: {
    midi: 64,
    note: 'E',
    accidental: '',
    octave: 4
  },
  65: {
    midi: 65,
    note: 'F',
    accidental: '',
    octave: 4
  },
  66: {
    midi: 66,
    note: 'F',
    accidental: '#',
    octave: 4
  },
  67: {
    midi: 67,
    note: 'G',
    accidental: '',
    octave: 4
  },
  68: {
    midi: 68,
    note: 'G',
    accidental: '#',
    octave: 4
  },
  69: {
    midi: 69,
    note: 'A',
    accidental: '',
    octave: 4
  },
  70: {
    midi: 70,
    note: 'A',
    accidental: '#',
    octave: 4
  },
  71: {
    midi: 71,
    note: 'B',
    accidental: '',
    octave: 4
  },
  72: {
    midi: 72,
    note: 'C',
    accidental: '',
    octave: 5
  },
  73: {
    midi: 73,
    note: 'C',
    accidental: '#',
    octave: 5
  },
  74: {
    midi: 74,
    note: 'D',
    accidental: '',
    octave: 5
  },
  75: {
    midi: 75,
    note: 'D',
    accidental: '#',
    octave: 5
  },
  76: {
    midi: 76,
    note: 'E',
    accidental: '',
    octave: 5
  },
  77: {
    midi: 77,
    note: 'F',
    accidental: '',
    octave: 5
  },
  78: {
    midi: 78,
    note: 'F',
    accidental: '#',
    octave: 5
  },
  79: {
    midi: 79,
    note: 'G',
    accidental: '',
    octave: 5
  },
  80: {
    midi: 80,
    note: 'G',
    accidental: '#',
    octave: 5
  },
  81: {
    midi: 81,
    note: 'A',
    accidental: '',
    octave: 5
  },
  82: {
    midi: 82,
    note: 'A',
    accidental: '#',
    octave: 5
  },
  83: {
    midi: 83,
    note: 'B',
    accidental: '',
    octave: 5
  },
  // 84: {
  //   midi: 84,
  //   note: 'C',
  //   accidental: '',
  //   octave: 6
  // },
}

export { midiToNote };
