import React from 'react';
import './upDown.scss';

import { generateUpDownChallenge } from '../common/handlers';

import AnswerButton from '../../components/answerButton/AnswerButton';
import NoteBox from '../../components/noteBox/NoteBox';
import { ReactComponent as PlaySymbol } from '../../images/play.svg';
import { ReactComponent as NextSymbol } from '../../images/forward.svg';
import Settings from '../Settings/Settings';
import NoteSelector from '../../components/noteSelector/NoteSelector';
import Slider from '../../components/slider/Slider';
import ClickyButton from '../../components/clickyButton/ClickyButton';

class UpDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxInterval: 6,
      roots: {
        60: true,
        67: true
      }
    };
  }

  handleChangeRoots = (roots) => {
    this.setState({ roots })
  }

  handleChangeMaxInterval = (n) => {
    this.setState({ maxInterval: parseInt(n) });
  }

  handleClickPlayChallenge = () => {
    let challenge;

    if (!!this.state.challenge && !!this.state.challenge.firstNote) {
      challenge = this.state.challenge;
    } else {
      challenge = generateUpDownChallenge(this.state.roots, this.state.maxInterval);

      this.setState({
        challenge
      })
    }

    const { firstNote, secondNote } = challenge;
    this.props.pianoService.playInterval(firstNote, secondNote)
  }

  handleClickNextChallenge = () => {
    this.clearAnswer();
    let challenge = generateUpDownChallenge(this.state.roots, this.state.maxInterval);

    this.setState({
      challenge
    })
    
    const { firstNote, secondNote } = challenge;
    this.props.pianoService.playInterval(firstNote, secondNote)
  }

  answer = (answerValue) => {
    this.setState({
      userAnswer: answerValue
    });
  }

  clearAnswer = () => {
    this.setState({
      userAnswer: null
    });
  }

  render() {
    return (
      <div className='up-down-root'>
        <Settings>
          <NoteSelector 
            notes={this.state.roots}
            setNotesFn={this.handleChangeRoots}
          />

          <div style={{height:"24px"}}></div>

          <Slider
            min={1}
            max={12}
            title="Max interval (semitones)"
            initialValue={6}
            value={this.state.maxInterval}
            handleChange={this.handleChangeMaxInterval}
          />
        </Settings>

        <div style={{'height': '48px', 'width': '100%'}}/>

        {/* Controls */}
        <div className='control-row'>
          <div className='control-column'>
            <div className='control-wrapper'>
              <ClickyButton
                onClick={this.handleClickPlayChallenge}
              >
                <PlaySymbol
                  className='control-symbol'
                ></PlaySymbol>
              </ClickyButton>
            </div>
          </div>
          
          <div className='control-column'>
            <div className='control-wrapper'>
              <ClickyButton
                onClick={this.handleClickNextChallenge}
              >
                <NextSymbol
                  className='control-symbol'
                ></NextSymbol>
              </ClickyButton>
            </div>
          </div>
        </div>

        {/* <div style={{'height': '24px', 'width': '100%'}}/> */}
        <div style={{'height': '34px', 'width': '100%'}}/>


        {/* Notes */}
        <div className='control-row'>
          <div className='control-column'>
            <div className='control-wrapper'>
              <NoteBox
                showInfo={!!this.state.userAnswer}
                instrument={this.props.pianoService}
                midiValue={this.state.challenge?.firstNote}
              ></NoteBox>
            </div>
          </div>
          
          <div className='control-column'>
            <div className='control-wrapper'>
              <NoteBox
                showInfo={!!this.state.userAnswer}
                instrument={this.props.pianoService}
                midiValue={this.state.challenge?.secondNote}
              ></NoteBox>
            </div>
          </div>
        </div>

        <div style={{'height': '32px', 'width': '100%'}}/>

        <div className="divider"></div>

        <div style={{'height': '34px', 'width': '100%'}}/>

        {/* Answers */}
        <div className='answers-container'>
          <AnswerButton
            text={"Up"}
            buttonAnswer={'up'}
            correctAnswer={this.state.challenge?.correctAnswer}
            userAnswer={this.state.userAnswer}
            onClick={this.answer}
          ></AnswerButton>

          <div style={{'height': '16px', 'width': '100%'}}/>

          <AnswerButton
            text={"Down"}
            buttonAnswer={'down'}
            correctAnswer={this.state.challenge?.correctAnswer}
            userAnswer={this.state.userAnswer}
            onClick={this.answer}
          ></AnswerButton>
        </div>
      </div>
    )
  }
}

export default UpDown;
