import React from "react";
import classnames from 'classnames';
import './clicky-button.scss';

const ClickyButton = ({ disabled, onClick, theme, noClick, children }) => {
  // console.log(theme);  
  return (<>
    <button
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={classnames({
        "clicky-button": true,
        "disabled": disabled,
        "default": !theme,
        "f-green": (theme === "green" && !disabled),
        "f-red": (theme === "red" & !disabled)
    })}>
      <span
        className={classnames({
          "front": true,
          "no-click": (noClick || disabled),
        })}
      >
        {children}
      </span>
    </button>
  </>)
}

export default ClickyButton;
