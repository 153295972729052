const perfectFifthUp = [
  {
    front: "C up P5",
    back: "G"
  },
  // {
  //   front: "C# up P5",
  //   back: "G#"
  // },
  // {
  //   front: "Db up P5",
  //   back: "Ab"
  // },
  {
    front: "D up P5",
    back: "A"
  },
  // {
  //   front: "D# up P5",
  //   back: "A#"
  // },
  // {
  //   front: "Eb up P5",
  //   back: "Bb"
  // },
  {
    front: "E up P5",
    back: "B"
  },
  {
    front: "F up P5",
    back: "C"
  },
  // {
  //   front: "F# up P5",
  //   back: "C#"
  // },
  // {
  //   front: "Gb up P5",
  //   back: "Db"
  // },
  {
    front: "G up P5",
    back: "D"
  },
  // {
  //   front: "G# up P5",
  //   back: "D#"
  // },
  // {
  //   front: "Ab up P5",
  //   back: "Eb"
  // },
  {
    front: "A up P5",
    back: "E"
  },
  // {
  //   front: "A# up P5",
  //   back: "F"
  // },
  // {
  //   front: "Bb up P5",
  //   back: "F"
  // },
  {
    front: "B up P5",
    back: "F#"
  },
]

export default perfectFifthUp;
