const intervals = {
  0: {
    semitones: 0,
    name: "Perfect unison",
    shortname: "P1",
    altname: "perf1"
  },
  1: {
    semitones: 1,
    name: "Minor second",
    shortname: "m2",
    altname: "min2"
  },
  2: {
    semitones: 2,
    name: "Major second",
    shortname: "M2",
    altname: "maj2"
  },
  3: {
    semitones: 3,
    name: "Minor third",
    shortname: "m3",
    altname: "min3"
  },
  4: {
    semitones: 4,
    name: "Major third",
    shortname: "M3",
    altname: "maj3"
  },
  5: {
    semitones: 5,
    name: "Perfect fourth",
    shortname: "P4",
    altname: "perf4"
  },
  6: {
    semitones: 6,
    name: "Tritone",
    shortname: "TT",
    altname: "TT"
  },
  7: {
    semitones: 7,
    name: "Perfect fifth",
    shortname: "P5",
    altname: "perf5"
  },
  8: {
    semitones: 8,
    name: "Minor sixth",
    shortname: "m6",
    altname: "min6"
  },
  9: {
    semitones: 9,
    name: "Major sixth",
    shortname: "M6",
    altname: "maj6"
  },
  10: {
    semitones: 10,
    name: "Minor seventh",
    shortname: "m7",
    altname: "min7"
  },
  11: {
    semitones: 11,
    name: "Major seventh",
    shortname: "M7",
    altname: "maj7"
  },
  12: {
    semitones: 12,
    name: "Perfect octave",
    shortname: "P8",
    altname: "perf8"
  }
};

export { intervals };
