import React, { useState } from 'react';
import './flashcards.scss'
import classNames from 'classnames';
import { shuffleArray } from '../../helpers/functions';

import Flashcard from './Flashcard/Flashcard';
import GroupSelector from './GroupSelector/GroupSelector';
import ClickyButton from '../../components/clickyButton/ClickyButton';


import worldCapitals from './questions/world-capitals';
import semitonesToIntervals from './questions/semitones-to-interval';
import perfectFifthUp from './questions/perfect-fifth-up';
import majorChords from './questions/major-chords';
import majorScales from './questions/major-scales';
import greekModes from './questions/greek-modes';

const groups = [
  {
    title: "Semitones to intervals",
    id: "semitonesToIntervals",
    data: semitonesToIntervals
  },
  {
    title: "World capitals",
    id: "worldCapitals",
    data: worldCapitals
  },
  {
    title: "Up a Perfect Fifth",
    id: "perfectFifthUp",
    data: perfectFifthUp
  },
  {
    title: "Major Chords",
    id: "majorChords",
    data: majorChords
  },
  {
    title: "Major Scales",
    id: "majorScales",
    data: majorScales
  },
  {
    title: "Greek Modes",
    id: "greekModes",
    data: greekModes
  },
]

const flipCard = ({flipFn, currentState}) => {
  flipFn(!currentState);
}

const changeMode = ({
  mode,
  setModeFn,
  selectedGroups,
  setPlayingCardsFn,
  setCurrentCardIdx
}) => {
  if (mode === "setup") {
    let result = [];

    for (let groupID in selectedGroups) {
      let group = groups.find((gg) => gg.id === groupID)

      result = result.concat(group.data)
    }
    
    shuffleArray(result);
    setPlayingCardsFn(result);
    setCurrentCardIdx(0);
    setModeFn("play")
  } else {
    setPlayingCardsFn([]);
    setModeFn("setup")
  }
}

const Flashcards = () => {
  const [mode, setMode] = useState("setup");
  const [selectedGroups, setSelectedGroups] = useState({});
  const [playingCards, setPlayingCards] = useState([]);
  const [currentCardIdx, setCurrentCardIdx] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div className='flashcards-root'>
      <div className='flashcards-control'>
        
        { mode === "setup" ? <>
          <div>
            {Object.keys(selectedGroups).length} selected 
          </div>
        </> : <>
          <div>
            Card {currentCardIdx + 1}/{playingCards.length} 
          </div>
        </> }
        

        <button
          className='flashcard-button'
          onClick={() => changeMode({
            mode: mode,
            setModeFn: setMode,
            selectedGroups: selectedGroups,
            setPlayingCardsFn: setPlayingCards,
            setCurrentCardIdx: setCurrentCardIdx
          })}
        >
          { mode === "setup" ? "Play" : "Back" }
        </button>
      </div>
      
      { mode === "setup" && (<>
        {groups.map(({ id, title }) => {
          return (
            <GroupSelector
              title={title}
              id={id}
              key={id}
              isSelected={selectedGroups[id]}
              options={selectedGroups}
              setFn={setSelectedGroups}
            ></GroupSelector>
          )
        })}

  
        <div
          style={{marginTop: "24px"}}
        >

        </div>
      </>)}

      { mode === "play" && (<>

        { currentCardIdx % 2 === 0 && (<>
          <Flashcard
            data={playingCards[currentCardIdx]}
            isFlipped={isFlipped}
            onClick={() => flipCard({
              flipFn: setIsFlipped,
              currentState: isFlipped
            })}
          ></Flashcard>
        </>)}
        
        { currentCardIdx % 2 === 1 && (<>
          <Flashcard
            data={playingCards[currentCardIdx]}
            isFlipped={isFlipped}
            onClick={() => flipCard({
              flipFn: setIsFlipped,
              currentState: isFlipped
            })}
          ></Flashcard>
        </>)}

        <div style={{marginTop: "36px"}}/>

        <ClickyButton
          theme="green"
          addClass="lalal"
          onClick={() => {
            if (currentCardIdx === playingCards.length - 1) {
              setMode("setup");
              setIsFlipped(false);

              return;
            }

            setCurrentCardIdx(currentCardIdx + 1)
            setIsFlipped(false);
          }}
        >
          NEXT
        </ClickyButton>

      <div style={{marginTop: "12px"}}/>

      <ClickyButton
        onClick={() => flipCard({
          flipFn: setIsFlipped,
          currentState: isFlipped
        })}
      >
        Flip
      </ClickyButton>

      </>)}
    </div>
  )
}

export default Flashcards;
