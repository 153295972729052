import React, { useState } from 'react';
import './slider.scss';

const getBackgroundSize = (value, min, max) => {
  const width = max - min;
  const progress = ((value - min) / width) * 100;
  
  return {
    backgroundSize: `${progress}% 100%`,
  };
};

const Slider = ({ handleChange, title, value, min, max, initialValue }) => {
  if (!min) {
    min = 0;
  }

  if (!value) {
    value = initialValue;
  }

  // const [value, setValue] = useState(initialValue || Math.round((max + min) / 2));

  return (
    <div className='slider'>
      <input
        type="range"
        value={value}
        min={min}
        max={max}
        onChange={(e) => handleChange(e.target.value)}
        style={getBackgroundSize(value, min, max)}
      />

      <div className='slider-title-container'>
        {title}
        {" : "} 
        {value}
      </div>
    </div>
  )
}

export default Slider;
