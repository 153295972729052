import React from 'react';
import './settings.scss';
import { ReactComponent as Gear } from '../../images/gear.svg';
import Accordion from '../../components/accordion/Accordion';

const Settings = ({ children }) => {
  return (
    <div className='settings-root'>
      <Accordion
        headerIcon={<Gear/>}
        headerText="Settings"
        centerHeader={true}
      >
        <div className='content-wrapper'>
          <div className='content-inner-wrapper'>
            {children}
          </div>
        </div>
      </Accordion>
    </div>
  )
}

export default Settings;
