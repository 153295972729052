import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import './accordion.scss';
import { ReactComponent as Chevron } from '../../images/chevron-up.svg';

function toggleAccordion(height, scrollHeight, setHeightFn, isOpen, setOpenFn) {
  setHeightFn(height === 0 ? (scrollHeight + 32) : 0); // hack - see padding on accordion-body &-open
  setOpenFn(!isOpen);
}

const Accordion = ({ headerIcon, headerText, centerHeader, children }) => {
  const content = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);

  return (
    <div className='accordion-wrapper'>
      <div
        className={classnames({
          'accordion-header': true,
          'accordion-header-open': isOpen
        })}

        onClick={() => toggleAccordion(
          height, content.current.scrollHeight, setHeight,
          isOpen, setIsOpen
        )}  
      >
        {centerHeader && <div className='accordion-dummy-for-center'></div>}
        
        <div className='accordion-header-content'>
          <div className='accordion-header-icon'>
            {headerIcon}
          </div>
          {headerText}
        </div>

        <div className='accordion-state-icon-wrapper'>
          <Chevron
            className={classnames({
              'accordion-state-icon': true,
              'accordion-state-icon-open': isOpen
            })}
          ></Chevron>
        </div>
        
      </div>

      <div
        ref={content}
        style={{'height': `${height}px`}}
        className={classnames({
          'accordion-body': true,
          'accordion-body-open': isOpen
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion;
