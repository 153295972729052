import React from 'react';
import classnames from 'classnames';
import './flashcard.scss';

const Flashcard = ({ data, isFlipped, onClick }) => {
  let back = data.back;

  if (Array.isArray(data.back)) {
    back = data.back.map((ii) => (
      <div style={{"margin-bottom": "24px"}}>
        {ii}
      </div>
    ))
  }

  return (
    <div     
      className={classnames({
        'flashcard': true
      })}
      onClick={onClick}
    >
      <div className={classnames({
        'card': true,
        'is-flipped': isFlipped
      })}>
        <div className="card-face card-face-front">{data.front}</div>
        <div className="card-face card-face-back">
        
          {back}
        </div>
      </div>
    </div>
  )
}

export default Flashcard;
