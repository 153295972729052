const greekModes = [
  {
    front: "Ionian",
    back: "Major scale"
  },
  {
    front: "Dorian",
    back: "Major with b3 b7 \n Minor with #6"
  },
  {
    front: "Phrygian",
    back: "Major with b2 b3 b6 b7 \n Minor with b2"
  },
  {
    front: "Lydian",
    back: "Major with #4"
  },
  {
    front: "Mixolydian",
    back: "Major with b7"
  },
  {
    front: "Aeolian",
    back: "Major with b3 b6 b7 \n Minor scale "
  },
  {
    front: "Locrian",
    back: "Major with b2 b3 b5 b6 b7 \n Minor with b2 b5"
  },
]

export default greekModes;
