import { Link } from 'react-router-dom';
import './home.scss';
import { ReactComponent as Music } from '../../images/music.svg';

function Home() {
  return (
    <div className='home'>
      <Link
        className='page-link'
        to={'/updown'}
      >
        <div className='icon-container'>
          <Music className='home-link-icon'></Music>
        </div>
        <div>
          <div className='home-link-title'> Up or Down</div>
          <div className='home-link-subtitle'>Is the interval ascending or descending?</div>
        </div>
      </Link>

      <Link
        className='page-link'
        to={'/intervals'}
      >
        <div className='icon-container'>
          <Music className='home-link-icon'></Music>
        </div>
        <div>
          <div className='home-link-title'>Intervals</div>
          <div className='home-link-subtitle'>Can you identify the interval?</div>
        </div>
      </Link>

      <Link
        className='page-link'
        to={'/flashcards'}
      >
        <div className='icon-container'>
          <Music className='home-link-icon'></Music>
        </div>
        <div>
          <div className='home-link-title'>Flashcards</div>
          <div className='home-link-subtitle'>Train your memory</div>
        </div>
      </Link>
 
      <Link
        className='page-link'
        to={'/relay'}
      >
        <div className='icon-container'>
          <Music className='home-link-icon'></Music>
        </div>
        <div>
          <div className='home-link-title'>Relay</div>
          <div className='home-link-subtitle'>Quick attack!</div>
        </div>
      </Link>
    </div>
  );
}

export default Home;
