import React, { useState } from 'react';
import { createHashRouter, RouterProvider } from "react-router-dom"
import './App.scss';

import Home from './domains/Home/Home';
import UpDown from './domains/UpDown/UpDown';
import Intervals from './domains/Intervals/Intervals';
import Flashcards from './domains/Flashcards/Flashcards';
import Relay from './domains/Flashcards/Relay';
import ErrorPage from './error-page';

import InstrumentService from './services/InstrumentService';
import Layout from './components/layout/Layout';

const PianoService = new InstrumentService(); 

const router = createHashRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: "/updown",
    element: (
      <Layout>
        <UpDown pianoService={PianoService}/>
      </Layout>
    )
  },
  {
    path: "/intervals",
    element: (
      <Layout>
        <Intervals pianoService={PianoService}/>
      </Layout>
    )
  },
  {
    path: "/flashcards",
    element: (
      <Layout>
        <Flashcards/>
      </Layout>
    )
  },
  {
    path: "/relay",
    element: (
      <Layout>
        <Relay/>
      </Layout>
    )
  }
]);

function App() {
  const [darkTheme, setDarkTheme] = useState(false);

  return (
    <div className={`app ${darkTheme ? 'dark-theme' : 'light-theme'}`}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
