import React, { useState, useRef } from 'react';
import './flashcards.scss'
import { shuffleArray } from '../../helpers/functions';

import Relaycard from './Flashcard/Relaycard';
import GroupSelector from './GroupSelector/GroupSelector';

import worldCapitals from './questions/world-capitals';
import semitonesToIntervals from './questions/semitones-to-interval';
import perfectFifthUp from './questions/perfect-fifth-up';
import majorChords from './questions/major-chords';
import majorScales from './questions/major-scales';
import minorChords from './questions/minor-chords';
import greekModes from './questions/greek-modes';
import { getRandomInteger } from '../../helpers/functions';

const groups = [
  {
    title: "Semitones to intervals",
    id: "semitonesToIntervals",
    data: semitonesToIntervals
  },
  {
    title: "World capitals",
    id: "worldCapitals",
    data: worldCapitals
  },
  {
    title: "Up a Perfect Fifth",
    id: "perfectFifthUp",
    data: perfectFifthUp
  },
  {
    title: "Major Chords",
    id: "majorChords",
    data: majorChords
  },
  {
    title: "Minor Chords",
    id: "minorChords",
    data: minorChords
  },
  {
    title: "Major Scales",
    id: "majorScales",
    data: majorScales
  },
  {
    title: "Greek Modes",
    id: "greekModes",
    data: greekModes
  },
]

const Relay = () => {
  const [mode, setMode] = useState("setup");
  const [selectedGroups, setSelectedGroups] = useState({});
  const [playingCards, setPlayingCards] = useState([]);
  const [currentCardIdx, setCurrentCardIdx] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  let timer = useRef(null)
  let count = 0;

  const permanentTick = (numberOfCards) => {
    console.log("nn", numberOfCards);

    if (count === 2) {
      let next = currentCardIdx
      while (next === currentCardIdx) {
        next = getRandomInteger(0, numberOfCards - 1)
      }

      setCurrentCardIdx(next)
      count = 0;
    } else {
      count += 1
    }
  }
  
  const changeMode = ({
    mode,
    setModeFn,
    selectedGroups,
    setPlayingCardsFn,
    setCurrentCardIdx
  }) => {
    if (mode === "setup") {
      let result = [];
  
      for (let groupID in selectedGroups) {
        let group = groups.find((gg) => gg.id === groupID)
  
        result = result.concat(group.data)
      }
      
      shuffleArray(result);
      setPlayingCardsFn(result);
      setCurrentCardIdx(0);
      setModeFn("play")
  
      timer.current = setInterval(
        () => permanentTick(result.length),
        1000)
    } else {
      clearInterval(timer.current)
      setPlayingCardsFn([]);
      setModeFn("setup")
    }
  }

  return (
    <div className='flashcards-root'>
      <div className='flashcards-control'>
        
        { mode === "setup" ? <>
          <div>
            {Object.keys(selectedGroups).length} selected 
          </div>
        </> : <>
          <div>
            Card {currentCardIdx + 1}/{playingCards.length} 
          </div>
        </> }
        

        <button
          className='flashcard-button'
          onClick={() => changeMode({
            mode: mode,
            setModeFn: setMode,
            selectedGroups: selectedGroups,
            setPlayingCardsFn: setPlayingCards,
            setCurrentCardIdx: setCurrentCardIdx
          })}
        >
          { mode === "setup" ? "Play" : "Back" }
        </button>
      </div>
      
      { mode === "setup" && (<>
        {groups.map(({ id, title }) => {
          return (
            <GroupSelector
              title={title}
              id={id}
              key={id}
              isSelected={selectedGroups[id]}
              options={selectedGroups}
              setFn={setSelectedGroups}
            ></GroupSelector>
          )
        })}

        <div style={{marginTop: "24px"}}>

        </div>
      </>)}

      { mode === "play" && (<>
        { currentCardIdx % 2 === 0 && (<>
          <Relaycard
            data={playingCards[currentCardIdx]}
            isFlipped={isFlipped}
          ></Relaycard>
        </>)}
        
        { currentCardIdx % 2 === 1 && (<>
          <Relaycard
            data={playingCards[currentCardIdx]}
            isFlipped={isFlipped}
          ></Relaycard>
        </>)}

        <div style={{marginTop: "36px"}}/>
      </>)}
    </div>
  )
}

export default Relay;
// https://docs.google.com/document/d/1sqvddUvWYQyD7cIudn7O18C5ncMEqc9yLyNx4ZkqKY0/edit