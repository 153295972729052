import React from 'react';
import classNames from 'classnames';
import './groupSelector.scss';
import { handleChangeMultiOptions } from '../../common/handlers';

const GroupSelector = ({ title, isSelected, id, options, setFn }) => {
  return (
    <div
      className={classNames({
      'group-selector-root': true,
      'selected': isSelected
      })}
      onClick={() => handleChangeMultiOptions(options, id, setFn, true)}  
    >
      <div className='title'>
        {title}
      </div>
    </div>
  )
}

export default GroupSelector;
