import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { ReactComponent as Headphones } from '../../images/headphones.svg';
import { ReactComponent as Bars } from '../../images/bars.svg';

class Header extends React.Component {
  constructor(props) {
    super(props)
  }

      //   {/* <Toggle
    //     isChecked={darkTheme}
    //     handleToggle={() => setDarkTheme(!darkTheme)}
    //   ></Toggle> */}

    //   {/* <input
    //     type="radio"
    //     onChange={(e) => console.log(e)}>
    //   </input> */}

  render() {
    return (
      <div className="header">
        <div className="header-main">
          <div className="headerContainer">
            <Link className="companyName" to={'/'}>
              <Headphones className='header-logo'/>
              Simple
            </Link>

            <Bars
              className="headerBars"
            ></Bars>
          </div>
        </div>
        <div className='header-bar'></div>
      </div>
    )
  }
}

export default Header;
