const minorChords = [
  {
    front: "Cm",
    back: "C - Eb - G"
  },
  {
    front: "C#m",
    back: "C# - E - G#"
  },
  {
    front: "Dbm",
    back: "Db - Fb - Ab"
  },
  {
    front: "Dm",
    back: "D - F - A"
  },
  {
    front: "D#m",
    back: "D# - F# - A#"
  },
  {
    front: "Ebm",
    back: "Eb - Gb - Bb"
  },
  {
    front: "Em",
    back: "E - G - B"
  },
  {
    front: "Fm",
    back: "F - Ab - C"
  },
  {
    front: "F#m",
    back: "F# - A - C#"
  },
  // {
  //   front: "Gbm",
  //   back: "Gb - Bbb - Db"
  // },
  {
    front: "Gm",
    back: "G - Bb - D"
  },
  {
    front: "G#m",
    back: "G# - B - D#"
  },
  {
    front: "Abm",
    back: "Ab - Cb - Eb"
  },
  {
    front: "Am",
    back: "A - C - E"
  },
  {
    front: "A#m",
    back: "A# - C# - E#"
  },
  {
    front: "Bbm",
    back: "Bb - Db - F"
  },
  {
    front: "Bm",
    back: "B - D - F#"
  },
];

export default minorChords;
