import React from 'react';
import './noteBox.scss';
import { midiToNote } from '../../helpers/notes';
import { ReactComponent as QuarterNote } from '../../images/quarter-note.svg';
import ClickyButton from '../clickyButton/ClickyButton';

const NoteBox =({ showInfo, instrument, midiValue }) => {
  console.log(midiValue)

  if (!midiValue) {
    return (
      <ClickyButton
        disabled={true}
      >
        <QuarterNote
          className='note-symbol'
        ></QuarterNote>
      </ClickyButton>
    )
  }
  
  const note = midiToNote[midiValue];

  return (
    <ClickyButton
      onClick={() => instrument.playNote(midiValue)}
    >
      <div className='button-content'>
        <QuarterNote
          className='note-symbol'
        ></QuarterNote>

        
          {showInfo && <div className='note-info'>
            <div className='flex-h-center'>
              {`${note.note}${note.accidental}${note.octave}`}
            </div>
            <div className='flex-h-center midi-info'>
              midi {note.midi}
            </div>
          </div>}
      </div>
    </ClickyButton>
  );
}

export default NoteBox;
